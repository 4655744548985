<template>
  <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">

    <div v-if="product && product.vendor">
      <div>

        <div v-if="product" class="tw-text-left tw-grid tw-grid-cols-8 tw-gap-x-8">
          <div class="tw-text-sm mb-3 tw-col-span-8">
            <span class="tw-text-red">{{$t('vendor.showcasework.wedding_offers')}}</span> /
            <router-link
              :to="{name: 'showcasework.show', params: { id: product.vendor.companies ? product.vendor.companies.slug ? product.vendor.companies.slug:product.vendor.id : product.vendor.id }}"
              class="tw-text-red">{{ product.vendor.name }}</router-link> /
            {{ product.name }}
          </div>
          <div v-if="images" class="tw-col-span-8">
            <gallery :images="images" />
          </div>
          <div class="tw-col-span-8">
            <div class="tw-flex tw-flex-wrap lg:tw-flex-nowrap tw-mt-4 tw-mb-2 lg:tw-mb-0">
              <div>
                <h1 class="tw-font-semibold tw-text-xl tw-leading-relaxed tw-mb-2">{{ product.name }}</h1>
                <div class="tw-text-sm mb-3">
                  <p>{{product.type.name}} {{ $t('vendor.offer.price') }}: {{ product.total_price_without_discount |
                  currency }} | {{ $t('vendor.offer.discount') }} ({{ product.discount}}%) | <span
                      class="tw-font-bold">Total: {{product.total_price_with_discount | currency}}</span></p>
                </div>

                <div class="tw-flex tw-items-center tw-text-sm tw-mb-4 tw-leading-relaxed tw-text-gray-n3"
                  v-if="product.categories.length > 0">
                  {{ product.categories[0].name }}
                </div>
              </div>
              <div class="tw-flex tw-items-center tw-space-x-2 tw-ml-0 lg:tw-ml-auto tw-mr-auto lg:tw-mr-8">
                <div v-if="!product.personalized">
                  <!--
                        <span :key="index" v-for="(preselectedDate, index) in preselectedDates.filter(item => !unavailableDates.includes(item))"
                              @click="changeDate(preselectedDate)"
                              :class="[selectedProduct.dates.includes(preselectedDate) ? 'tw-bg-green-100' : 'tw-bg-gray-n1']"
                              class="tw-ml-1 tw-cursor-pointer tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium  tw-text-gray-n4">
                          {{ dateFormat(preselectedDate) }}
                        </span>
                        -->
                  <!-- Prolem ako ima puno datuma available
                        <div v-if="availableDates.length > 0 && this.selectedProduct.dates.length == 0">

                          <span :key="index" v-for="(preselectedDate, index) in availableDates"
                                @click="changeDate(preselectedDate)"
                                :class="[selectedProduct.dates.includes(preselectedDate) ? 'tw-bg-green-100' : 'tw-bg-gray-n1']"
                                class="tw-ml-1 tw-cursor-pointer tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium  tw-text-gray-n4">
                            {{ dateFormat(preselectedDate) }}

                          </span>
                        </div>
                        -->
                  <div v-if="this.selectedProduct.dates.length > 0">
                    <span
                      class="tw-bg-green-100 tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium  tw-text-gray-n4">
                      {{ dateFormat(this.selectedProduct.dates[0])}}
                      <x-icon size="1x" class="tw-ml-1 tw-cursor-pointer"
                        @click="removeDate(selectedProduct.dates[0]); showError('You haven’t selected your date!')" />
                    </span>
                  </div>


                </div>
                <div v-else>
                  <span
                    class="tw-bg-green-100 tw-cursor-pointer tw-inline-flex tw-items-center tw-px-2.5 tw-py-0.5 tw-rounded-full tw-text-xs tw-font-medium  tw-text-gray-n4">
                    {{this.selectedProduct.dates[0]}}
                  </span>

                </div>

                <button
                  class="tw-p-4 tw-text-white tw-rounded-xl tw-inline-flex tw-font-semibold tw-text-sm tw-leading-relaxed"
                  :class="[selectedProduct.dates.length === 0 ? 'tw-bg-gray-n1':'tw-bg-red']"
                  v-if="user && !user.vendor || 1==1"
                  @click="selectedProduct.dates.length === 0  && !valid ? showError('You haven’t selected your date!'):startWithOrder()">
                  Book for {{ total_price | currency }}
                </button>
              </div>
            </div>
          </div>
          <div class="tw-text-sm tw-col-span-8 lg:tw-col-span-6">
            <v-alert v-if="error" class="tw-rounded-lg" color="tw-bg-lightred" icon="false">
              <template v-slot:prepend>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.29 3.8602L1.82002 18.0002C1.64539 18.3026 1.55299 18.6455 1.55201 18.9947C1.55103 19.3439 1.64151 19.6873 1.81445 19.9907C1.98738 20.2941 2.23675 20.547 2.53773 20.7241C2.83871 20.9012 3.18082 20.9964 3.53002 21.0002H20.47C20.8192 20.9964 21.1613 20.9012 21.4623 20.7241C21.7633 20.547 22.0127 20.2941 22.1856 19.9907C22.3585 19.6873 22.449 19.3439 22.448 18.9947C22.4471 18.6455 22.3547 18.3026 22.18 18.0002L13.71 3.8602C13.5318 3.56631 13.2807 3.32332 12.9812 3.15469C12.6817 2.98605 12.3438 2.89746 12 2.89746C11.6563 2.89746 11.3184 2.98605 11.0188 3.15469C10.7193 3.32332 10.4683 3.56631 10.29 3.8602V3.8602Z"
                    stroke="#E14D4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12 9V13" stroke="#E14D4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M12 17H12.01" stroke="#E14D4D" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>

              </template>
              <div class="tw-m-0 tw-text-white tw-pl-2">
                <p class="tw-font-bold tw-m-0 tw-inline-block">{{error.error}} | {{error.message}}</p>
                <p class="tw-m-0" v-if="selectedProduct.dates.length === 0">Please check <button
                    class="tw-underline tw-text-red" @click="showCalendar($event,product.id)">vendor’s calendar</button>
                  to book a date of your wedding.</p>
                <!--
                <span v-else-if="!user">
                  <router-link :to="{ name:'login'}" class="tw-underline tw-text-red tw-font-bold">Sign In
                  </router-link>
                  !
                </span>
                -->
              </div>
            </v-alert>
            <h5 class="tw-font-semibold tw-mb-3">Description</h5>
            <p class="tw-leading-relaxed" v-html="product.description">
            </p>
          </div>

          <!-- Prolem ako ima puno datuma available

          <div v-if="availableDates.length > 0" class="tw-text-sm tw-col-span-8 lg:tw-col-span-6">
            <div class="tw-py-2 tw-flex tw-text-xs tw-text-green tw-space-x-1 tw-items-center">
              <calendar-icon size="1x" />
                <span v-for="(datum,index) in availableDates" :key="index" class="tw-inline-flex tw-items-center">
                  | <check-circle-icon v-show="selectedProduct.dates.includes(datum)" size="1x" class="tw-bg-green-100 tw-ml-1 tw-mr-1"></check-circle-icon>
                    <span class="tw-cursor-pointer" @click="changeDate(datum)">{{dateFormat(datum)}}</span>
                </span>
            </div>
          </div>
          -->


          <div class="tw-text-sm tw-col-span-8 lg:tw-col-span-6" v-if="product.products.length > 0">
            <h5 class="tw-font-semibold tw-mb-3">Items in the package (Included)</h5>
            <div class="tw-space-y-4">
              <VendorShowcaseProductDetail v-for="(subProduct, index) in product.products" :key="index"
                :product=subProduct :vendor=product.vendor></VendorShowcaseProductDetail>
            </div>
          </div>
          <div class="tw-text-sm tw-col-span-6 tw-mt-2" v-if="product.addons.length > 0">
            <h5 class="tw-font-semibold tw-mb-3">Addons (Optional)</h5>
            <div class="tw-space-y-4">
              <VendorShowcaseProductAddonDetail v-for="(subAddon, index) in product.addons" :key="index"
                :product=subAddon :vendor=product.vendor @addonSelected="addonSelected">
              </VendorShowcaseProductAddonDetail>
            </div>
          </div>
          <!--
          <div class="tw-text-sm tw-col-span-6">
            <h5 class="tw-font-semibold tw-mb-3 tw-mt-4">Additional information</h5>
            <router-link class="tw-text-red tw-inline-flex tw-items-center" target="_blank"
              :to="{path: '/vendor/'+product.vendor.id+'/terms-and-conditions' }">
              {{$t('vendor.toc')}}
              <arrow-up-right-icon class="tw-w-4 tw-h-4 tw-ml-1" />
            </router-link>
          </div>
          -->
        </div>
        <!--
        <div v-else-if="!loading" class="tw-text-left tw-grid tw-grid-cols-8 tw-gap-x-8">
          <div class="tw-text-sm mb-3 tw-col-span-8">
            <span class="tw-text-red">{{$t('vendor.showcasework.wedding_offers')}}</span> /
            No showcase work created.
          </div>

        </div>
        -->
      </div>

      <!-- Ordered msg-->
      <v-dialog v-if="product.vendor.settings" v-model="orderStatus" hide-overlay persistent width="600">
        <v-card v-if="1==2">
          <v-card-title class="headline grey lighten-2">
            Added to Cart / {{ product.vendor.settings.auto_booking === true ? 'Auto-Booking' : 'Booking request' }}
          </v-card-title>
          <v-card-text class="mt-2">

            {{ ordermsg }}
            <v-divider />
            <div v-if="product.vendor.settings.auto_booking === true">
              This booking request will be automatically approved.
            </div>
            <div v-else>
              This booking request needs to be approved by Vendor. Mango & Lola allows 48 hours for the vendor to reply,
              but most do reply within a few hours.
            </div>

          </v-card-text>
          <v-card-actions>
            <v-btn outlined small @click="orderStatus=!orderStatus">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Your item has been added to cart.
          </v-card-title>
          <v-card-text class="mt-2">

            <v-btn color="yellow" rounded @click="openCart()">
              Proceed to check out
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn outlined small text @click="goToShowcaseWork()">
              Continue to shopping
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Order error msg-->
      <v-dialog v-if="product.vendor.settings" v-model="orderError" hide-overlay persistent width="600">
        <v-card>
          <v-card-title class="headline red lighten-2">
            Oops, something went wrong!
          </v-card-title>
          <v-card-text class="mt-2">

            {{ ordermsg }}

          </v-card-text>
          <v-card-actions>
            <v-btn outlined small @click="orderError=!orderError">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog v-if="product.vendor.settings" v-model="orderConfirmation" hide-overlay persistent width="600">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{
            product.vendor.settings.auto_booking === true ? $t('vendor.offer.on_the_spot_booking') :
            $t('vendor.offer.booking_request')
            }}
            <v-spacer />
            <v-btn icon small @click="orderConfirmation=!orderConfirmation">
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>

          <!-- This is for area coverage check-->
          <v-form ref="form" v-model="valid" :lazy-validation="lazy" v-if="user || 1==1">

            <v-card-text v-if="product.vendor.settings.area_coverage_check">

              <v-alert :type="selectedProduct.country ? 'success':'info'" text>
                <p v-if="!selectedProduct.country">{{ $t('vendor.offer.area_info_1') }}</p>
                <p v-else>{{ $t('vendor.offer.area_info_2') }}</p>
              </v-alert>


              <v-autocomplete :disabled="!product.vendor.settings.area_coverage_check" v-model="selectedProduct.country"
                :items="product.vendor.settings.countries" item-value="id" item-text="name" :rules="[rules.required]"
                :label="$t('vendor.offer.state_select')" prepend-icon="mdi-map" chips :clearable="true"
                :hide-selected="true" :deletable-chips="true">
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('vendor.offer.state_not_found') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>

            </v-card-text>

            <!-- End -->


            <!-- Weding team-->
            <div v-if="user">
              <v-card dense elevation="0" v-if="user.purchasingEventMember.length">
              <v-card-subtitle>
                {{ $t('vendor.offer.purchase_event_selection') }}
              </v-card-subtitle>
              <v-card-text>

                <v-chip-group v-model="selectedProduct.event_id" :mandatory="true">
                  <!-- None -->
                  <v-chip filter outlined :value="null">
                    {{ $t('vendor.offer.purchase_event_selection_none') }}
                  </v-chip>
                  <!-- My events -->
                  <!--
                  <template v-for="(item) in user.myEvents">

                    <v-tooltip
                        top
                        :key="item.id"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            v-bind="attrs"
                            v-on="on"
                            filter outlined :value="item.id">
                          {{ $t('vendor.offer.purchase_event_selection_my') }}
                        </v-chip>
                      </template>
                      {{ $t('vendor.offer.event_date') }} {{ item.date }}
                      <template v-for="(newlywed,index) in item.newlyweds">
                        {{ newlywed.first_name }} {{ newlywed.last_name }}
                        <template v-if="index == 0">
                          &
                        </template>
                      </template>
                      {{ $t('vendor.offer.event') }}
                    </v-tooltip>
                  </template>
                  -->
                  <!-- Guests -->
                  <template v-for="(eventitem) in user.purchasingEventMember">
                    <v-tooltip top :key="eventitem.id">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="attrs" v-on="on" filter outlined :value="eventitem.id">
                          <template v-for="(newlywed,index) in eventitem.newlyweds">
                            {{ newlywed.first_name }} {{ newlywed.last_name }}
                            <template v-if="index == 0">
                              &
                            </template>
                          </template>
                          {{ $t('vendor.offer.event') }}
                        </v-chip>
                      </template>
                      {{ $t('vendor.offer.event_date') }} {{ eventitem.date }}
                    </v-tooltip>
                  </template>


                </v-chip-group>

              </v-card-text>
            </v-card>
            </div>
          </v-form>
          <!-- End -->

          <v-card-actions>
            <v-btn color="orange darken-1" small @click="addToCart()" :disabled="!valid">

              <v-icon left>mdi-cart-outline</v-icon>
              {{ $t('vendor.offer.add_to_cart') }}
            </v-btn>
            <v-spacer />
            <v-btn icon small @click="orderConfirmation=!orderConfirmation">
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-actions>


        </v-card>
      </v-dialog>

      <!-- Ordering progress-->
      <v-dialog v-model="ordering" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-title>
            {{ $t('vendor.offer.proceeding') }}
          </v-card-title>
          <v-card-text>
            {{ $t('vendor.offer.standby') }}
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Calendar -->
      <v-dialog v-model="showCalendarDialog" width="320px" @click:outside="showCalendarDialog=!showCalendarDialog">
        <v-card elevation="0">
          <!--        <v-toolbar dark >-->
          <!--          <v-btn icon dark @click="showCalendarDialog=!showCalendarDialog">-->
          <!--            <v-icon>mdi-close</v-icon>-->
          <!--          </v-btn>-->
          <!--          <v-toolbar-title class="text-xs">{{ $t('vendor.offer.select_date') }}</v-toolbar-title>-->
          <!--          <v-spacer></v-spacer>-->
          <!--        </v-toolbar>-->


          <div class="tw-flex tw-flex-col tw-items-center tw-p-6 tw-bg-white">
            <p class="tw-text-xl tw-font-semibold tw-mb-4 tw-text-center">{{ $t('vendor.offer.select_date') }}</p>
            <!-- Calendar for packages -->
            <spinner v-if="selectedProduct.id == product.id & loading"></spinner>
            <div v-else-if="selectedProduct.id == product.id && showcalendar && !loading">

            <Calendar
                :availability=availability
                :unavailability=unavailability
                :settings=vendor.settings
                @selectDates="returnSelectedDates($event,product.id)"
                :selectedDates=selectedProduct.dates
            ></Calendar>

            </div>



            <!-- Calendar for packages end -->
          </div>

        </v-card>

      </v-dialog>

    </div>
  </div>


</template>


<script>


import VendorShowcaseProductDetail from '../components/VendorShowcaseProductDetail.vue'
import VendorShowcaseProductAddonDetail from '../components/VendorShowcaseProductAddonDetail.vue'
import Calendar from '@/components/CalendarByPackage.vue'
//import Reviews from '../components/Reviews.vue';
//import ShowcaseworkFilter from '../components/DataFilter.vue';
//import Messages from '../components/Message.vue';

import { mapGetters } from 'vuex'
import Gallery from "@/components/Gallery";
import { XIcon } from "vue-feather-icons";

export default {
  //middleware: ['guest'],
  components: {
    VendorShowcaseProductDetail,
    VendorShowcaseProductAddonDetail,
    Calendar,
    //Reviews,
    //ShowcaseworkFilter,
    //Messages
    Gallery,
    //ArrowUpRightIcon,
    XIcon
  },
  metaInfo() {
    return {
      title: this.product ? this.product.vendor.name:null,
    }
  },
  data() {
    return {
      orderConfirmation: false,
      valid: true,
      lazy: true,
      active_tab: null,
      addonNr: 0,
      imgItem: 0,
      orderStatus: false,
      orderError: false,
      ordermsg: '',
      ordering: false,
      product: null,
      vendor: null,
      slug: {},
      products: {},
      showcasework: {},
      settings: {},
      images: [],
      chat: null,
      loading: false,
      drawerR: true,
      drawerL: true,
      items: [
        { title: 'Contact us', icon: 'mdi-email' },
        { title: 'Instagram', icon: 'mdi-instagram' },
        { title: 'Facebook', icon: 'mdi-facebook' },
      ],
      selectedProduct: {
        id: null,
        addons: [],
        dates: [],
        vendor_id: null,
        comment: '',
        country: null,
        city: null,
        event_id: null,
        shipping_id: null,
        quantity: 1
      },
      mini: true,
      miniR: false,
      showcalendar: false,
      showCalendarDialog: false,
      selectDates: [],
      availability: [],
      unavailability: [],
      gallery: false,
      error: null,
      ploading: false,
      qloading: false,
      unavailableDates: [],
      rules: {
        required: v => !!v || 'This field is required',
        maxlength: len => v => (v || '').length <= len || `Invalid character max length ${len}`,
      },
      orderitems: [
        { 'key': '1', 'name': 'Name A-Z', 'value': 'name', 'icon': 'mdi-alphabetical', 'color': 'black' },
        { 'key': '2', 'name': 'Name Z-A', 'value': '-name', 'icon': 'mdi-alphabetical', 'color': 'grey' },
        { 'key': '3', 'name': 'Latest created', 'value': '-id', 'icon': 'mdi-chevron-double-right', 'color': 'black' },
        { 'key': '4', 'name': 'Oldest created', 'value': 'id', 'icon': 'mdi-chevron-double-left', 'color': 'grey' },
      ],
    }
  },
  mounted() {
    this.getProduct(false);
    if (this.preselectedDates.length > 0) {
      // this.selectedProduct.dates = this.preselectedDates.slice(0, 1);
    }


  },
  computed: {
    total_price() {
      var selectedAddons = this.selectedProduct.addons.map(a => a.price);
      let summed = 0;
      for (let key in selectedAddons) {
        summed += Number(selectedAddons[key]);
      }
      /*
      if(this.product.package_discount > 0 && this.product.package_discount <= 100){
        summed = summed - ((summed / 100)*this.product.package_discount);
      }
      */

      return Number(this.product.total_price_with_discount) + summed;
    },
    ...mapGetters({
      user: 'auth/user',
      guestGhost: 'auth/guestGhost',
      filterParams: 'filter/getFilterParams',
      getViewMyCart: 'cart/getViewMyCart',
      //getOrderStatus: 'cart/getOrderStatus'
    }),
    preselectedDates() {
      if (!this.filterParams || !this.filterParams["filter[availability_dates_in]"]) return [];
      return this.filterParams["filter[availability_dates_in]"].split(',');
    },
    availableDates() {

      if (this.product && this.vendor) {

        if (this.product.quote) {
          return this.selectDates;
        }

        if (!this.vendor.settings.set_my_available_dates) {
          return this.getDifferences(this.product.unavailability);
        } else {
          return this.returnDates(this.product.availability);
        }

      } else {
        return [];
      }

    },
  },
  watch: {

    'selectedProduct.dates': function () {

      //console.log("selected Product date", this.selectedProduct.dates.length);
      if (this.selectedProduct.dates.length > 0) {
        this.valid = true;
        this.error = null;
        if (!this.user) {
          //this.valid = false;
          //this.showError('To Book a Vendor, you need to ');
        }
      } else {
        this.valid = false;
      }
    },
    product: function () {

      if (this.product.personalized) {
        if (this.product.quote) {
          this.selectedProduct.dates = [this.product.quote.dates[0].datef];
          this.selectDates = [this.product.quote.dates[0].datef];
          //console.log("Quote",this.selectedProduct.dates);
        }
      }

      if (this.availableDates.length > 0) {
        this.selectedProduct.dates = this.availableDates.slice(0, 1);
        this.valid = true;
        if (!this.user) {
          //this.showError('To Book a Vendor, you need to Sign In!');
        }
      } else {
        this.valid = false;
        this.showError('You haven’t selected your date!');
      }

      /*
      if(this.product.unavailability){

        let unavailableDates = [];
        let unavailability = this.product.unavailability;
        unavailability.forEach(element => {
          element.dates.forEach(date => {
            unavailableDates.push(date.datef);
          });
        });
        this.unavailableDates = unavailableDates;

        if(!this.unavailableDates.includes(this.selectedProduct.dates)){
          this.selectedProduct.dates = this.preselectedDates.filter(item => !unavailableDates.includes(item)).slice(0,1);
        }
      }
      */

    },
    getViewMyCart: function () {
      if (this.getViewMyCart) this.ordering = false;
    }

  },
  methods: {
    changeDate(date) {

      //if(this.unavailableDates.includes(date)) return;
      this.selectedProduct.dates = [date];
    },
    removeDate(date) {
      //console.log(date);
      var index = this.selectedProduct.dates.map(item => item).indexOf(date);
      //console.log(index);

      if (index > -1) {
        this.selectedProduct.dates.splice(index, 1);
      }
    },
    getDifferences(data) {
      return this.preselectedDates.filter(item => !this.returnDates(data).includes(item)) ?? null;
    },
    returnDates(availability) {
      var date = [];
      availability.forEach(element => {
        element.dates.forEach(element => {
          date = date.concat(element.datef);
        })
      })
      return date;
    },
    addonSelected(data) {
      var index = this.selectedProduct.addons.map(item => item.id).indexOf(data.id);
      //console.log(index);
      if (index == -1) {
        this.selectedProduct.addons = this.selectedProduct.addons.concat(data)
      } else if (index > -1) {
        this.selectedProduct.addons.splice(index, 1);
      }

      // this.selectedProduct.addons.push(data);
    },
    async addToCart() {
      //console.log("place order");
      await this.$refs.form.validate();
      if (!this.valid) return;
      this.orderConfirmation = false;

      if (this.unavailableDates.includes(this.selectedProduct.dates)) return;
      //console.log("Add Item to Cart")
      await this.cartItemAdd(this.product);
    },
    async cartItemAdd(product) {
      this.selectedProduct.id = product.id;
      if (!product.quote) {
        if (!this.selectedProduct.id) return;
        if (!this.selectedProduct.dates) return;
      } else {
        var dates = this.product.quote.dates.map(day => day.datef);
        this.selectedProduct.dates = dates;
        //console.log(dates);
      }

      if (this.selectedProduct.event == 0) {
        this.selectedProduct.event = null;
        this.selectedProduct.event_id = null;
      }
      this.ordering = true; // progress load

      let uri = '/api/cart-items';
      this.$http.post(uri, this.selectedProduct)
        .then(({ data }) => {
          this.addedCartItem(data);
        })
        .catch((error) => {
          this.ordering = false;
          this.error = error.data;
          this.$emit('error', error)
        })
    },
    async addedCartItem(data) {
      //this.$toast.success(data.message, "Success"),
      //this.$emit('ordered', data.message);
      //console.log("addedCartItem");
      //this.orderStatus = true;
      if(!this.user && data.token){
        //console.log("token:",data.token);
        await this.$store.dispatch('auth/saveToken', {
          token: data.token,
          remember: true
        });
        //const token = await this.$store.getters['auth/token'];
        //console.log("token store:",token);

      }
      await this.$store.dispatch('auth/fetchUser');
      this.error = null;
      await this.$store.dispatch('cart/fetchCart');
      this.openCart();
      this.ordering = false;
    },
    showError(error) {
      this.error = {
        'error': error
      };
    },
    hideError() {
      this.error = null;
    },
    startWithOrder() {
      this.orderConfirmation = true;
    },
    async openCart() {
      let cart = true;
      await this.$store.dispatch('cart/setCartWindow', { cart });
      //this.orderStatus = !this.orderStatus;

    },
    goToShowcaseWork() {
      this.orderStatus = !this.orderStatus;
      this.$router.back();
      //this.$router.push({name: 'showcasework.show', params: {id: this.selectedProduct.vendor_id}});

    },
    goToSocialNet(link) {
      window.open(link);
    },
    async ordered(order) {
      this.ordermsg = order;
      // await this.getProduct(); // napomena: Ukljuciti ako ima direktan order
      this.orderStatus = true;

    },
    onselectedDates(dates) {
      this.selectDates = dates;
    },

    async getProduct(e) {
      this.loading = true;

      let id = this.$route.params.id;
      if (this.preselectedDates) {
        e = true;
      }
      let param = e == true ? this.filterParams : null;

      if (this.selectDates.length > 0) {
        let qparams = null;
        qparams["filter[availability_dates_in]"] = this.selectDates;
        param = e == true ? qparams : null;
      }

      let uri = '/api/products/' + id;
      this.$http.get(uri, { params: param })
        .then((response) => {
          this.product = response.data.data;
          this.vendor = response.data.data.vendor;
          this.images = response.data.data.images;
          this.selectedProduct.vendor_id = this.product.vendor.id;
          //this.showcalendar = true;
        })
        .catch(error => {
          //console.log(error);
          //this.$router.push({name: 'feed'});
          this.error = error;
        })
        .finally(() =>
          //this.vendor.showcasework ? this.images = this.vendor.showcasework.images:null,
          this.loading = false
        );
    },
    async showCalendar(e, product) {
      //console.log("showCalendar", e);

      this.availability = [];
      await this.fetchAvailability(product);
      //e.srcElement.style.color = "coral"
      //this.selectedProduct.addons = null;
      this.selectedProduct.dates = [];
      this.selectedProduct.id = product;
      this.showCalendarDialog = true;

    },
    async fetchAvailability(product) {
      this.loading = true;
      this.availability = [];
      this.unavailability = [];

      this.$http.get('api/products/' + product + '/calendar')
        .then(({ data }) => {
          this.availability = data.data.availability
          this.unavailability = data.data.unavailability
          this.product.availability = data.data.availability
          this.product.unavailability = data.data.unavailability
        })
        .catch(error => {
          console.log('Error loading feed data: ' + error)
        }).finally(() =>
          this.loading = false,
          this.showcalendar = true,

          //console.log("showCalendar")
        )
    },
    async returnSelectedDates(e, id) {
      this.selectedProduct.id = id;
      this.selectedProduct.dates = e;
      this.selectDates = e;
      this.showcalendar = false;
      this.showCalendarDialog = false;
      //const params = this.filterParams;
      //console.log(this.filterParams);
      //params["filter[availability_dates_in]"] = this.selectDates[0].toString();
      //await this.$store.dispatch('filter/setFilterParams', params);
      //console.log(this.filterParams);
      //await this.getProduct(true);
      this.hideError();
    },
  }
}
</script>
<style scoped>
.instagram {

  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, .25);
}
</style>
