<template>
  <div class="tw-border tw-border-gray-n1 tw-rounded-lg tw-px-6 tw-py-4">


    <div class="tw-flex tw-flex-wrap lg:tw-flex-nowrap">
      
      <div :class="{'tw-flex tw-items-center tw-justify-center tw-bg-gray-n1 tw-rounded-lg' : !product.image}"
           class="tw-flex-grow-0 tw-flex-shrink-0 tw-mr-4 tw-mb-4 lg:tw-mb-0" style="flex-basis: 280px; height: 192px">
        <gallery v-if="product.images.length > 0" :images="product.images" :id="'offer-gallery' + product.id" :limit="1"
                 thumbWrapperClass="" :show-border="false"
                 :gallery-setup="{}" default-image-class="tw-h-48"/>
        <div v-else>
          <image-icon class="tw-w-14 tw-h-14 tw-text-gray-n2"/>
        </div>
      </div>
      <div class="tw-flex-grow">
        <div class="tw-flex tw-flex-wrap lg:tw-flex-nowrap tw-items-center">
          <div class="tw-text-xl tw-mb-2 tw-leading-relaxed tw-font-semibold tw-text-black tw-mr-auto">
            {{ product.name }}
          </div>

        </div>
        <div class="tw-text-gray-n3 tw-font-semibold tw-mb-3">
          {{ product.categories.map(item => item.name).join(',') }}
        </div>
        <p class="tw-leading-relaxed tw-mb-3" v-html="product.description">
        </p>
        <p class="tw-leading-relaxed tw-text-xl tw-leading-relaxed">
          Price: ${{ product.price }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

//import VendorAddons from './VendorAdd-ons.vue'
//import ProductsImageGallery from './ProductsImageGallery.vue'

import { mapGetters } from 'vuex'

import Gallery from "@/components/Gallery";
import { ImageIcon } from "vue-feather-icons";

export default {
  name: 'FeedCard',
  props: ['vendor', 'product', 'selectDates'],

  components: {
    //VendorAddons,
    //ProductsImageGallery,
    Gallery,
    ImageIcon,
  },

  watch: {
    selectDates: function () {
      this.selectedProduct.dates = this.selectDates;
    },
    event: function () {
      //this.selectedProduct.event = this.event;
      this.selectedProduct.event_id = this.event == 0 ? null : this.event;
      //console.log(this.selectedProduct.event_id);

    }
  },

  data() {
    return {
      orderConfirmation: false,
      ordering: false,
      loginDialog: false,
      BookingOptionHelp: false,
      datesAlert: true,
      loading: false,
      comment: '',
      quantity: '1',
      product_list: [],
      dates: [],
      selectedDay: [], // Add state to store selected day
      showcalendar: false,
      showCalendarDialog: false,
      sc: 'hidden',
      availability: [],
      unavailability: [],
      selectedProduct: {
        id: null,
        addons: null,
        dates: null,
        vendor_id: this.vendor.id,
        comment: '',
        country: null,
        city: null,
        event: null,
        shipping_id: null,
        quantity: 1
      },
      event: [],
      selectedAddons: [],
      rules: {
        required: value => !!value || 'Required.',
        required_select: v => !!(v && v.length) || 'Item is required',
        max: v => v.length <= 1000 || 'Max 1000 characters'
      },
      valid: true,
      lazy: true,
      gallery: false
    }
  },

  computed: {
    attributes() {
      return this.availability.map(t => ({
        key: t.id,
        dates: t.json_dates,
        customData: t,
        test: 1
      }));
    },
    ...mapGetters({
      user: 'auth/user',
    }),

  },
  methods: {
    signed(event) {
      if (event == 1) {
        this.loginDialog = false;
      }
    },
    close() {
      this.$emit("input", !this.value);
    },
    dataRefreshAfterOrder() {
      this.dates = [],
          //this.fetchAvailability(this.selectedProduct.id);
          this.selectedProduct.id = null;
      this.selectedProduct.dates = null;
      this.selectedProduct.addons = null;
      this.ordering = false;
      //this.$store.dispatch('budget/fetchBudget')

    },
    onselectedDates(event, id) {
      this.selectedProduct.id = id;
      this.selectedProduct.dates = event;
    },
    onselectedAddons(event) {
      //this.selectedProduct.id = id;
      this.selectedProduct.addons = event;//.filter(addon => addon.checked == true);
      this.selectedAddons = event;

    },
    async showCalendar(e, product) {
      console.log("showCalendar");
      if (this.selectedProduct.id != product) {
        this.availability = [];
        await this.fetchAvailability(product);
        //e.srcElement.style.color = "coral"
        //this.selectedProduct.addons = null;
        this.selectedProduct.dates = null;
        this.selectedProduct.id = product;
        this.showCalendarDialog = true;
      } else if (this.showcalendar == false) {
        ///this.selectedProduct.dates = null;
        this.showcalendar = true;
        this.showCalendarDialog = true;
      } else {
        this.showcalendar = false;
        this.showCalendarDialog = false;
      }


    },
    getAttr(index) {
      return this.attrs[index];
    },
    returnDates(availability) {
      var date = [];
      availability.forEach(element => {
        element.dates.forEach(element => {
          date = date.concat(element.date);
        })
      })
      return date;
    },
    getDates(index) {
      var date = [];
      this.dates[index].forEach(element => {
        //console.log(element);
        date = date.concat(this.convertDate(element));
        //date = date.concat(element.toISOString().slice(0,10));

      });
      //console.log(date);
      return date;

    },
    daySelect(a) {
      if (a.srcElement.style.backgroundColor == "coral") {
        a.srcElement.style.backgroundColor = "";
      } else {
        a.srcElement.style.backgroundColor = "coral";
      }
    },
    convertDate(date) {
      var Day = new Date(date);
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var yyyy = date.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      }
      Day = yyyy + '-' + mm + '-' + dd;
      return Day;
    },
    dayClicked(e, day) {
      console.log(day);
      var index = this.selectedDay.indexOf(day.id);
      if (day.isDisabled == false && index == -1) {
        this.selectedDay = this.selectedDay.concat(day.id)
        this.daySelect(e);
      } else if (index > -1) {
        this.selectedDay.splice(index, 1);
        this.daySelect(e);
      }
    },
    async fetchAvailability(product) {
      this.loading = true;
      this.availability = [];
      this.unavailability = [];

      this.$http.get('api/products/' + product + '/calendar')
          .then(({data}) => {
            this.availability = data.data.availability
            this.unavailability = data.data.unavailability
          })
          .catch(error => {
            console.log('Error loading feed data: ' + error)
          }).finally(() =>
              this.loading = false,
          this.showcalendar = true,

          console.log("showCalendar")
      )
    },
    startWithOrder(product) {
      this.product = product;
      this.orderConfirmation = true;
    },
    async placeOrder() {


      console.log("place order");
      await this.$refs.form.validate();
      if (!this.valid) return;
      this.orderConfirmation = false;
      this.order(this.product);
    },

    async order(product) {

      console.log("order");
      this.selectedProduct.id = product.id;
      if (!product.quote) {
        if (!this.selectedProduct.id) return;
        if (!this.selectedProduct.dates) return;
      } else {
        var dates = this.product.quote.dates.map(day => day.datef);
        this.selectedProduct.dates = dates;
        //console.log(dates);
      }

      if (this.selectedProduct.event == 0) {
        this.selectedProduct.event = null;
        this.selectedProduct.event_id = null;
      }
      this.ordering = true; // progress load

      /* IN : product,index

      this.product_list = product.id;
      this.comment = product.name;
      var datesSelected = [];
      datesSelected = this.getDates(index);

      const formData = new FormData();
      formData.append('comment', this.comment);
      formData.append('quantity', this.quantity);
      formData.append('product_list[]', this.product_list);
      //formData.append('start_date', this.dates.start);
      //formData.append('end_date', this.dates.end);
      formData.append('dates', JSON.stringify(datesSelected));
      console.log("axios: " + JSON.stringify(datesSelected));
      //axios;
      */
      //let uri = '/api/orders';

      /*
           const { data } = await this.$store.dispatch('cart/addCartItem',this.selectedProduct);
           this.$emit('ordered', data.message)
           await this.dataRefreshAfterOrder();

          let uri = '/api/cart-items';
          const { data } = await this.$http.post(uri, this.selectedProduct);
          this.$emit('ordered', data.message);
          await this.dataRefreshAfterOrder();
          await this.$store.dispatch('cart/fetchCartItems');
      */

      let uri = '/api/cart-items';
      this.$http.post(uri, this.selectedProduct)
          .then(({data}) => {
            this.addedCartItem(data);
          })
          .catch(({error}) => {
            this.ordering = false,
                this.$emit('error', error)
          })

    },
    async addedCartItem(data) {
      //this.$toast.success(data.message, "Success"),
      this.$emit('ordered', data.message);
      await this.dataRefreshAfterOrder(),
          await this.$store.dispatch('cart/fetchCartItems')
    },
    handleSubmitOrder() {
      this.$emit('submitted', {
        comment: this.comment,
        quantity: this.quantity,
        product_list: this.product_list
      });
    },
  }

}
</script>
