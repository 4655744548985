<template>
  <div class="tw-border tw-border-gray-n1 tw-rounded-lg tw-px-6 tw-py-4">


    <div class="tw-flex tw-flex-wrap lg:tw-flex-nowrap">
      
      <div :class="{'tw-flex tw-items-center tw-justify-center tw-bg-gray-n1 tw-rounded-lg' : !product.image}"
           class="tw-flex-grow-0 tw-flex-shrink-0 tw-mr-4 tw-mb-4 lg:tw-mb-0" style="flex-basis: 280px; height: 192px">
        <gallery v-if="product.images.length > 0" :images="product.images" :id="'offer-gallery' + product.id" :limit="1"
                 thumbWrapperClass="" :show-border="false"
                 :gallery-setup="{}" default-image-class="tw-h-48"/>
        <div v-else>
          <image-icon class="tw-w-14 tw-h-14 tw-text-gray-n2"/>
        </div>
      </div>
      <div class="tw-flex-grow">
        <div class="tw-flex tw-flex-wrap lg:tw-flex-nowrap tw-items-center">
          <div class="tw-text-xl tw-mb-2 tw-leading-relaxed tw-font-semibold tw-text-black tw-mr-auto">
            {{ product.name }}
          </div>
          <div v-if="product.type.name == 'Add-on'">

              <v-checkbox
                @click="selectAddon()"
                v-model="product.checked"
                >
              </v-checkbox>
         
          </div>
        </div>
        <div class="tw-text-gray-n3 tw-font-semibold tw-mb-3">
          {{ product.categories.map(item => item.name).join(',') }}
        </div>
        <p class="tw-leading-relaxed tw-mb-3">
          {{ product.description }}
        </p>
        <p class="tw-leading-relaxed tw-text-xl tw-leading-relaxed">
          Price: ${{ product.price }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>


//import VendorAddons from './VendorAdd-ons.vue'
//import ProductsImageGallery from './ProductsImageGallery.vue'

import Gallery from "@/components/Gallery";
import { ImageIcon } from "vue-feather-icons";

export default {
  name: 'AddonDetail',
  props: ['vendor', 'product'],

  components: {
    //VendorAddons,
    //ProductsImageGallery,
    Gallery,
    ImageIcon,
  },

  watch: {
    product: function () {
  
    },
  },

  data() {
    return {
      loading: false,
      gallery: false
    }
  },

  computed: {
  },
  methods: {

    selectAddon(){
      this.product.quantity = 1;
      this.$emit("addonSelected", this.product);
    },












  }

}
</script>
