<template>


  
      <!--
      N:{{returnDates(product.unavailability)}}
      A:{{returnDates(product.availability)}}
                    :disabled-dates="returnDates(product.unavailability)"    
 
        v-if="returnDates(availability).length > 0 || returnDates(unavailability).length > 0"

      -->
            <v-calendar-io
              class="vc-border-none"
              v-model="dates"
              :min-date="new Date()"
              :attributes='attributes'  
              :available-dates="this.settings.set_my_available_dates ? returnDates(availability):null"
              :disabled-dates="!this.settings.set_my_available_dates ? returnDates(unavailability):null"
              >

                    <!-- slot-scope="{ day, attributes }" -->
                    <div 
                    slot='day-content' 
                    slot-scope="{ day }"
                    >
                    
                      <span v-if="available(day)" v-on:click='dayClicked($event,day)'
                          :key="day.day"
                          class="btn day-label text-sm text-gray-900"
                        >
                        {{ day.day }}
                        

                      </span>
                      <span v-else
                          :key="day.day"
                          class="btn day-label text-sm text-gray-400"
                        >
                        <del> {{ day.day }}</del>
                      </span>

                      <!--
                        <div
                            v-for="{key, customData} in attributes"
                            :key="key">

                              <div v-if="customData.products && settings.set_my_available_dates === true">
                                  <div
                                        v-for="(product, index) in customData.products"
                                        :key="index">

                                        <div v-if="customData.available === true">

                                            <div v-if="product.type.name = 'Package'" >

                                                <span
                                                    v-if="!day.isDisabled & available(day)"
                                                    class="tw-text-xs tw-bg-green-100 tw-rounded tw-rounded-full tw-border">
                                                  {{product.special_price | currency}}
                                                </span>
                                            </div>

                                        </div>
                                        <div v-else>

                                          <v-chip
                                                  color="red"
                                                  x-small
                                                  outlined
                                                  disabled
                                                >
                                                n/a
                                            </v-chip>

                                        </div>

                                  </div>
                              </div>

                        </div>
                      -->


                    </div>

            </v-calendar-io>
            <!--
            <div v-else>
                    <div class="alert alert-warning" role="alert">
                        No available dates for this product... 
                    </div>
            </div>
            -->

</template>

<script>

export default {
  name: 'Calendar',
  props: ['availability','unavailability','settings','selectedDates'],
  
  data () {
        const now = new Date();
        const month = now.getMonth();
        const year = now.getFullYear();
        const day = now.getDate();
        return {
        showDisabled: false,
        disabledSelectedDays: [],
        disabledSelectedDaysData: [],
        selectedDay: [],
        dates: [],
        today: new Date(year, month, day) * 1,
        }
  },

computed: {
     attributes() {

          var data = [];

          if(this.settings.set_my_available_dates){
            data = this.availability;
          }else{
            data = this.unavailability;
          }
          
          return data.map(t => ({
            key: t.id,
            dates:t.json_dates,
            customData: t,
          }));
    },
    availableDates(){
      return this.returnDates(this.availability);
    },
    unavailableDates(){
      return this.returnDates(this.unavailability);
    }

  },

  methods: {
        available(day){
          
          //var val = day.id + ' 00:00:00'
          var val = day.id;
          var dates = '';
          var index = 0;
          //console.log("available date",val);

          if(this.settings.set_my_available_dates){
            
            dates = this.availableDates;
            index = dates.indexOf(val);
            
/*
          console.log(available);
          console.log(index);

          console.log(val);
          console.log(day.isDisabled);
*/

              if(index == -1){
                //console.log("availableDates",false);
                return false;
              }else{
                //console.log("availableDates",true)
                return true;
              }

          }else{
            dates = this.unavailableDates;
            index = dates.indexOf(val);
            //console.log("unavailableDates")
/*
          console.log(available);
          console.log(index);

          console.log(val);
          console.log(day.isDisabled);
*/
              if( index == -1){
                //console.log("unavailableDates",true);
                return true;
              }else{
                //console.log("unavailableDates",false);
                return false;
              }

          }

     

          
        },
        returnDates(availability){
            var date = [];
            availability.forEach(element => {
                element.dates.forEach(element => {
                 date = date.concat(element.datef);
                })
            })
            
             //date = date.concat(new Date().setDate(new Date().getDate() - 2000));
             //date = date.concat(new Date());
             
             return date;
        },
         dayBooked(a) {
            a.srcElement.style.backgroundColor = "gray";
            var span_text = a.srcElement.innerText;
            //a.srcElement.innerText = 'Not available';
            setTimeout(function (){
               a.srcElement.style.backgroundColor = "";
                //a.srcElement.innerText = span_text;
                a.srcElement.innerHTML = '<del class="text-sm text-gray-400">'+span_text+'</del>';

            }, 500);     
        },
      getAttr(index){
            return this.attrs[index];
          },

    getDates(index){
      var date = [];
      this.dates[index].forEach(element => {
        //console.log(element);
          date = date.concat(this.convertDate(element));
          //date = date.concat(element.toISOString().slice(0,10));
          
        });
        //console.log(date);
        return date;
        
    },
    daySelect(a) {
            if(a.srcElement.style.backgroundColor == "coral"){
            a.srcElement.style.backgroundColor = "";
            }else{
            a.srcElement.style.backgroundColor = "coral";
            }
        },
    convertDate(date){
        var Day = new Date(date);
        var dd = date.getDate();
        var mm = date.getMonth()+1; 
        var yyyy = date.getFullYear();
            if(dd<10) 
            {
                dd='0'+dd;
            } 

            if(mm<10) 
            {
                mm='0'+mm;
            } 
        Day = yyyy + '-' + mm + '-' + dd;
        return Day;
      },
        dayClicked(e,day) {

         var index = this.selectedDay.indexOf(day.id);
         console.log(index);
         console.log(day.isDisabled);
          if(day.isDisabled == false && index == -1){
          this.selectedDay = this.selectedDay.concat(day.id)
          this.daySelect(e);
          }else if(index > -1){
            this.selectedDay.splice(index, 1);
            this.daySelect(e);
          }else{
              this.dayBooked(e);
            index = this.disabledSelectedDays.indexOf(day.id);
            if(index == -1){
              this.showDisabled = true;
              this.disabledSelectedDays = this.disabledSelectedDays.concat(day.id)
              //this.fetchUnavailabilityDetails();
            }else if(index > -1){
              this.disabledSelectedDays.splice(index, 1);
            }
          }
          this.showAddNew = true;

        },
      selectDates(){
        this.$emit('selectDates', this.selectedDay);
      },
      
  },
  watch: {
      selectedDay: function(){
        this.selectDates();
      }
  }
}
</script>
